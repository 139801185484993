@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'SequelSans-BlackBody';
        src: url('../../public/fonts/sequel-sans-black-body-text.eot');
        src: url('../../public/fonts/sequel-sans-black-body-text.eot?#iefix') format('embedded-opentype'),
        url('../../public/fonts/sequel-sans-black-body-text.woff2') format('woff2'),
        url('../../public/fonts/sequel-sans-black-body-text.woff') format('woff'),
        url('../../public/fonts/sequel-sans-black-body-text.ttf') format('truetype'),
        url('../../public/fonts/sequel-sans-black-body-text.svg#youworkforthem') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'SequelSans-BookBody';
        src: url('../../public/fonts/sequel-sans-book-body-text.eot');
        src: url('../../public/fonts/sequel-sans-book-body-text.eot?#iefix') format('embedded-opentype'),
        url('../../public/fonts/sequel-sans-book-body-text.woff2') format('woff2'),
        url('../../public/fonts/sequel-sans-book-body-text.woff') format('woff'),
        url('../../public/fonts/sequel-sans-book-body-text.ttf') format('truetype'),
        url('../../public/fonts/sequel-sans-book-body-text.svg#youworkforthem') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'SequelSans-BookOblBody';
        src: url('../../public/fonts/sequel-sans-book-oblique-body-text.eot');
        src: url('../../public/fonts/sequel-sans-book-oblique-body-text.eot?#iefix') format('embedded-opentype'),
        url('../../public/fonts/sequel-sans-book-oblique-body-text.woff2') format('woff2'),
        url('../../public/fonts/sequel-sans-book-oblique-body-text.woff') format('woff'),
        url('../../public/fonts/sequel-sans-book-oblique-body-text.ttf') format('truetype'),
        url('../../public/fonts/sequel-sans-book-oblique-body-text.svg#youworkforthem') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'SequelSans-RomanBody';
        src: url('../../public/fonts/sequel-sans-roman-body-text.eot');
        src: url('../../public/fonts/sequel-sans-roman-body-text.eot?#iefix') format('embedded-opentype'),
        url('../../public/fonts/sequel-sans-roman-body-text.woff2') format('woff2'),
        url('../../public/fonts/sequel-sans-roman-body-text.woff') format('woff'),
        url('../../public/fonts/sequel-sans-roman-body-text.ttf') format('truetype'),
        url('../../public/fonts/sequel-sans-roman-body-text.svg#youworkforthem') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'SequelSans-SemiBoldBody';
        src: url('../../public/fonts/sequel-sans-semi-bold-body-text.eot');
        src: url('../../public/fonts/sequel-sans-semi-bold-body-text.eot?#iefix') format('embedded-opentype'),
        url('../../public/fonts/sequel-sans-semi-bold-body-text.woff2') format('woff2'),
        url('../../public/fonts/sequel-sans-semi-bold-body-text.woff') format('woff'),
        url('../../public/fonts/sequel-sans-semi-bold-body-text.ttf') format('truetype'),
        url('../../public/fonts/sequel-sans-semi-bold-body-text.svg#youworkforthem') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    article p {
        margin-bottom: 0.75rem;
        text-align: center;
    }

    article strong {
        @apply font-sequel-semibold;
    }

    article a, form a {
        @apply underline hover:no-underline text-primary-link font-sequel-semibold;
    }

    article ul, article ol,
    .property-update ul,
    .property-update ol {
        margin-bottom: 1rem;
        padding-left: 1rem;
    }

    article ul li, article ol li, .property-update ul li, .property-update ol li {
        list-style: initial;
    }

    article ol li, .property-update ol li {
        list-style: decimal;
    }

    .uploaded .preloader {
        display: none;
    }
}

@layer components {
    :root {
        --vh: 1vh;
    }

    .property-grid {
        height: calc((var(--vh, 1vh) * 100) - 42px);
    }

    @screen sm {
        .property-grid {
            height: calc((var(--vh, 1vh) * 100) - 48px);
        }
    }

    @screen md {
        .property-grid {
            height: calc((var(--vh, 1vh) * 100) - 60px);
        }
    }

    @screen lg {
        .property__text--cols-2 {
            column-count: 2;
        }

        .property__text p {
            text-align: left;
        }
    }

    /*.property-grid__item {
        height: calc((100% - 42px) / 4);
    }*/
}
